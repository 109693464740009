/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import { isEmpty, selectedStoryTemplate, selectedStoryTemplateSettings } from "../isomorphic/utils";
import get from "lodash/get";
import camelCase from "lodash/camelCase";
import kebabCase from "lodash/kebabCase";
import { defaultTheme, defaultDarkTheme, defaultPremiumIconColor } from "../isomorphic/constants";

export function getUpdatedStoryConfig({
  config,
  templateConfig,
  storyPageType,
  storyStoreField,
  story,
  enableDarkMode,
}) {
  const {
    iconColor = defaultPremiumIconColor,
    iconStyle = "star",
    enablePremiumStoryIcon = false,
    darkIconColor = defaultPremiumIconColor,
  } = get(config, ["pagebuilder-config", "general", "rows", "premiumStoryIcon"], {});

  const premiumStoryIconConfig = {
    iconStyle,
    enablePremiumStoryIcon,
    iconColor: enableDarkMode ? iconColor : darkIconColor,
  };
  const timeFormat = get(config, ["pagebuilder-config", "general", "timeFormat"], "12hours");
  const dateFormat = get(config, ["pagebuilder-config", "general", "dateFormat"], "dd-mon-yyyy");
  const enableTimeTranslation = get(config, ["pagebuilder-config", "general", "enableTimeTranslation"], false);
  const getVisualStoryConfig = get(config, ["pagebuilder-config", "story", "text-story"], {});
  const getStoryConfig = get(config, ["pagebuilder-config", storyStoreField, storyPageType], {});
  const pbStoryConfig = story["story-template"] === "visual-story" ? getVisualStoryConfig : getStoryConfig;
  const storyTemplateSettings = selectedStoryTemplateSettings(storyPageType, config, storyStoreField);

  const defaultTemplateConfig = getDefaultTemplateConfig({
    config,
    story,
    pbStoryConfig,
    timeFormat,
    storyPageType,
    storyStoreField,
    enableDarkMode,
    dateFormat,
    enableTimeTranslation,
  });
  const updatedConfig = { ...defaultTemplateConfig, ...templateConfig, ...storyTemplateSettings };
  const storyConfig = !isEmpty(pbStoryConfig) ? updatedConfig : defaultTemplateConfig;
  const { enableLocalization = false, localizedElements = {} } = get(
    config,
    ["pagebuilder-config", "general", "localization"],
    {}
  );

  const localizedElementData = enableLocalization ? localizedElements : {};
  const {
    months: localizedMonths,
    meridiem: localizedMeridiem,
    publishTime: localizedPublishTime,
    buttonLabels = {},
  } = localizedElementData;
  const {
    publishedOn: localizedPublishedOn,
    updatedOn: localizedUpdatedOn,
    timeToRead: localizedTimeToRead,
    keyEvents: localizedKeyEvents,
    authorLabel: localizedAuthorLabel,
    guestAuthorLabel: localizedGuestAuthorLabel,
  } = buttonLabels;

  const authorDetailsCopy = storyConfig.authorDetails || {};

  return {
    ...storyConfig,
    premiumStoryIconConfig,
    publishedDetails: {
      ...storyConfig.publishedDetails,
      localizedPublishedOn,
      localizedUpdatedOn,
      localizedTimeToRead,
      localizedMonths,
      localizedMeridiem,
      localizedPublishTime,
      timeFormat,
      localizedKeyEvents,
      dateFormat,
      enableTimeTranslation,
    },
    authorDetails: {
      ...authorDetailsCopy,
      opts: {
        ...authorDetailsCopy.opts,
        localizedAuthorLabel,
        localizedGuestAuthorLabel,
      },
    },
  };
}

function getDefaultTemplateConfig({
  config,
  story,
  storyPageType,
  storyStoreField,
  pbStoryConfig,
  timeFormat,
  enableDarkMode,
}) {
  const storyTemplate = selectedStoryTemplate(storyPageType, config, storyStoreField);
  const isStoryInfiniteScrollEnabled =
    get(
      config,
      ["pagebuilder-config", "infiniteScroll", "infiniteScrollType"],
      get(config, ["themeAttributes", "infinite-scroll"], "disabled")
    ) !== "disabled";
  const headerTheme = get(config, ["pagebuilder-config", "header", "headerStyle"], "header_1");
  const showAllCards = -1;
  const { asideSlots = [], "story-slots": storySlots = {} } = pbStoryConfig;

  let theme = get(pbStoryConfig, ["settings", "theme"], defaultTheme);
  if (enableDarkMode) {
    theme = get(pbStoryConfig, ["settings", "darkTheme"], defaultDarkTheme);
  }

  const mountAtPrefix = get(config, ["mountAt"]) || "";

  const stickyStyle = {
    header_5: "top-header-five",
    header_6: "top-header-six",
  };

  return {
    theme,
    verticalShare: "",
    shareIconType: "plain-svg",
    templateType: storyTemplate,
    noOfVisibleCards: showAllCards,
    showSectionTag: true,
    publishedDetails: {
      enablePublishedTime: true,
      enableUpdatedTime: false,
      showReadTime: false,
      timeFormat,
    },
    storySlots,
    asideCollection: {
      data: null,
      slots: asideSlots,
      config: {
        title: "Related Stories",
        theme,
        collectionNameBorderColor: enableDarkMode ? "#ffffff" : "#000",
        showAuthor: true,
        showTime: true,
        showReadTime: false,
        mountAtPrefix,
        fallbackImageUrl: get(config, ["pagebuilder-config", "general", "fallbackImage", "url"]),
        stickyTopStyle: stickyStyle[headerTheme] || "",
      },
    },
    authorDetails: { template: "default", opts: { hideBio: true, hideImage: false } },
    premiumStoryIconConfig: {
      iconColor: defaultPremiumIconColor,
      iconStyle: "star",
      enablePremiumStoryIcon: false,
    },
    isStoryInfiniteScrollEnabled,
    buttonText: get(config, ["pagebuilder-config", "infiniteScroll", "buttonText"], "Read More"),
  };
}

export function elapsedTime(seconds) {
  const MINUTE = 60;
  const HOUR = MINUTE * 60;
  const DAY = HOUR * 24;
  const WEEK = DAY * 7;
  const MONTH = DAY * 30;
  const YEAR = DAY * 365;

  if (seconds < MINUTE) {
    return [Math.round(seconds), "second"];
  } else if (seconds < HOUR) {
    return [Math.round(seconds / MINUTE), "minute"];
  } else if (seconds < DAY) {
    return [Math.round(seconds / HOUR), "hour"];
  } else if (seconds < WEEK) {
    return [Math.round(seconds / DAY), "day"];
  } else if (seconds < MONTH) {
    return [Math.round(seconds / WEEK), "week"];
  } else if (seconds < YEAR) {
    return [Math.round(seconds / MONTH), "month"];
  } else {
    return [Math.round(seconds / YEAR), "year"];
  }
}
export function getStoryTemplate(story, pbConfig) {
  const storyTemplates = ["text", "photo", "video", "live-blog", "listicle"];
  const { "story-template": storyTemplate } = story;
  const isCustomStory = !storyTemplates.includes(storyTemplate);
  const customStoryTemplate =
    isCustomStory && get(pbConfig, ["customStory", `${camelCase(storyTemplate)}-story`, "storyType"], "text");
  return isCustomStory ? kebabCase(customStoryTemplate) : storyTemplate;
}

// fixes  story card timestamp issue when a page is server side rendered  and shows server time by
// updating timestamp in browser so that local time is used for story elapsed time > 23 hours

export async function fixDates(config, pbConfig) {
  const elemts = document.querySelectorAll(".arr__timeago");

  const languageCode = get(config, ["language", "ietf-code"], "en");

  const direction = get(config, ["language", "direction"], "ltr");
  const isLocalizedNumber = get(config, ["isLocalizedNumber"], false);

  const { enableLocalization = false, localizedElements = {} } = get(pbConfig, ["general", "localization"], {});
  const { enableTimeTranslation } = get(pbConfig, ["general"], {});
  const { months, meridiem, publishTime, buttonLabels = {} } = localizedElements;
  const { timeToRead = "", keyEvents } = buttonLabels;

  const localizationConfig = enableLocalization
    ? {
        localizedTimeToRead: timeToRead,
        localizedMonths: months,
        localizedMeridiem: meridiem,
        localizedPublishTime: publishTime,
        localizedKeyEvents: keyEvents,
        direction,
        enableTimeTranslation,
      }
    : {
        enableTimeTranslation,
      };

  const { formatter } = await import("@quintype/arrow");

  elemts.forEach((element) => {
    const timeNow = Date.now();
    const storyTimestamp = Date.parse(element.dateTime); // unix timestamp
    const suffix = storyTimestamp < timeNow ? "ago" : "from now";
    const ignoreUnits = ["second", "minute", "hour"];
    const seconds = Math.round(Math.abs(timeNow - storyTimestamp) / 1000);

    const [value, unit] = elapsedTime(seconds);

    /**
     * call formatter only if time elpased is greater than 23 hours ie 24 hours, 2 days/weeks/months/years
     * No need to update timestamp for relative time ie 10 hours ago
     */

    if (!ignoreUnits.includes(unit) || (unit === "hour" && value > 23)) {
      const timestamp = formatter(
        value,
        unit,
        suffix,
        storyTimestamp,
        localizationConfig,
        languageCode,
        isLocalizedNumber,
        enableTimeTranslation,
        enableLocalization
      );
      element.textContent = timestamp;
      const date = new Date(element.dateTime);
      element.title = `${date.toLocaleDateString()} ${date.toLocaleTimeString()} `;
    }
  });
}
