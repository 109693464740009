/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import React from "react";
import { CustomLink } from "../custom-link";
import get from "lodash/get";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { AccessType } from "@quintype/components";
import LogedinUser from "../../../assets/icons/logedin-user-icon.svg";
import { SvgHandler } from "../svg-handler/svg-handler";
import "./user-profile.m.css";

class UserDataBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showUserData: false,
      loadingSubscriptionPlans: false,
    };
    this.toggleUserData = this.toggleUserData.bind(this);
  }

  componentDidUpdate() {
    if (
      this.props.memberSubscriptions &&
      this.props.memberSubscriptions.length === 0 &&
      !this.state.loadingSubscriptionPlans
    ) {
      this.getUserSubscriptions();
    }
  }

  getUserSubscriptions = async () => {
    this.setState({ loadingSubscriptionPlans: true });
    const { subscriptions } = await this.props.getSubscriptionForUser();
    this.props.updateMemberSubscriptions(subscriptions);
    this.setState({ subscriptions });
  };

  toggleUserData = () => {
    this.setState({ showUserData: !this.state.showUserData });
  };

  async logoutHandler(event) {
    event.persist();
    event.preventDefault();
    event.stopPropagation();
    const { logout } = await import("@quintype/bridgekeeper-js");
    await logout().then(() => {
      global.talktype && global.talktype.accountUserLogout();
      this.props.updateMember(null);
      window.location.reload();
    });
  }

  fallbackImage() {
    const image = get(this.props, ["member", "avatar-url"]);
    if (image) {
      return <img styleName="avatar" src={image} loading="lazy" alt="author-avatar" />;
    }
    return (
      <span>
        <SvgHandler xlinkHref={LogedinUser} clazzName="author-icon" width="24" height="24" viewBox="0 0 24 24" />
      </span>
    );
  }

  render() {
    const { enableLocalization = false, localizedElements = {} } = get(
      this.props,
      ["config", "pagebuilder-config", "general", "localization"],
      {}
    );
    const { buttonLabels = {}, signInSignUp = {} } = enableLocalization ? localizedElements : {};
    const { myProfile: localizedMyProfile } = buttonLabels;
    const { signOut: localizedSignOut } = signInSignUp;

    return (
      <>
        <div
          data-testid="user-details"
          onClick={this.toggleUserData}
          styleName={`user-details ${this.props.customCssClasses}`}
          className="login-user-details"
        >
          {this.fallbackImage()}
          {this.state.showUserData && (
            <div styleName="overlay">
              <div styleName="user-data" className="user-data">
                <ul>
                  <li>
                    <CustomLink href={"/user-details"} callback={this.toggleUserData}>
                      {localizedMyProfile || "My profile"}
                    </CustomLink>
                  </li>
                  <li>
                    <div>
                      <a href={"#"} onClick={(event) => this.logoutHandler(event)}>
                        {localizedSignOut || "Sign out"}
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}
function UserDataTemplate(props) {
  const email = get(props, ["member", "email"]);
  const phone = get(props, ["member", "metadata", "phone-number"], "1234");
  const PbAccessTypeConfig = get(props, ["config", "pagebuilder-config", "general", "accesstypeIntegration"], {});
  const isStaging = get(PbAccessTypeConfig, ["accesstypeMode"]) === "staging";
  const {
    accesstypeKey = "",
    enableAccesstype = false,
    accessTypeProdHost = "",
    accessTypeStgHost = "",
    bridgeKeeperIntegrationId = "",
  } = PbAccessTypeConfig;

  const storyId = get(props, ["story", "id"]);
  return (
    <AccessType
      enableAccesstype={enableAccesstype}
      isStaging={isStaging}
      accessTypeKey={accesstypeKey} // "9rm1ijqQmGfJ2kwQ9zt2gWhK"
      accessTypeBkIntegrationId={bridgeKeeperIntegrationId}
      email={email}
      phone={phone}
      storyId={storyId}
      prodHost={accessTypeProdHost}
      stagingHost={accessTypeStgHost}
    >
      {({ getSubscriptionForUser }) => <UserDataBase getSubscriptionForUser={getSubscriptionForUser} {...props} />}
    </AccessType>
  );
}

UserDataBase.propTypes = {
  member: PropTypes.object,
  logout: PropTypes.func,
  subscriptions: PropTypes.array,
  getSubscriptionForUser: PropTypes.func,
  updateMemberSubscriptions: PropTypes.func,
  memberSubscriptions: PropTypes.array,
  customCssClasses: PropTypes.string,
  updateMember: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    config: get(state, ["qt", "config"]),
    member: get(state, ["member"], null),
    subscriptions: get(state, ["subscriptions"], []),
    memberSubscriptions: get(state, ["memberSubscriptions"], []),
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateMemberSubscriptions: (subscriptions) => dispatch({ type: "MEMBER_SUBSCRIPTIONS_UPDATED", subscriptions }),
  updateMember: (member) => dispatch({ type: "MEMBER_UPDATED", member }),
});

export const UserData = connect(mapStateToProps, mapDispatchToProps)(UserDataTemplate);
