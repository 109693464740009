/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import {
  SHOW_MEMBER_METADATA_POPUP,
  MEMBER_METADATA_UPDATED,
  MEMBER_METADATA,
  UPDATE_SINGLE_SIGN_ON,
  UPDATE_FORGOT_PASSWORD,
  UPDATE_SIGN_IN,
  UPDATE_SIGN_UP,
} from "../isomorphic/constants";
import { getCookie } from "../isomorphic/utils";
import get from "lodash/get";

const getEnableDarkModeCookie = getCookie("enableDarkMode") || false;
const checkDarkModeEnabled = getEnableDarkModeCookie === "true";

function headerReducer(
  state = {
    isSidebarVisible: false,
    isSearchFormVisible: false,
    primarySubmenu: null,
    isMegaMenuVisible: false,
    isDarkModeEnabled: checkDarkModeEnabled,
  },
  action
) {
  switch (action.type) {
    case "TOGGLE_SIDEBAR_MENU":
      return { ...state, isSidebarVisible: !state.isSidebarVisible };
    case "OPEN_SIDEBAR_MENU":
      return { ...state, isSidebarVisible: true };
    case "CLOSE_SIDEBAR_MENU":
      return { ...state, isSidebarVisible: false };
    case "TOGGLE_SEARCHFORM":
      return { ...state, isSearchFormVisible: !state.isSearchFormVisible };
    case "CLOSE_SEARCHFORM":
      return { ...state, isSearchFormVisible: false };
    case "OPEN_PRIMARY_SUBMENU":
      return { ...state, primarySubmenu: action.payload.menuId };
    case "CLOSE_PRIMARY_SUBMENU":
      return { ...state, primarySubmenu: null };
    case "TOGGLE_MEGAMENU":
      return { ...state, isMegaMenuVisible: !state.isMegaMenuVisible };
    case "TOGGLE_DARK_MODE":
      return { ...state, isDarkModeEnabled: action.isDarkModeEnabled };
    case "CLOSE_ALL":
      return {
        ...state,
        isSidebarVisible: false,
        isSearchFormVisible: false,
        primarySubmenu: null,
        isMegaMenuVisible: false,
      };
    default:
      return state;
  }
}

function loginReducer(
  state = {
    signIn: {
      currentScreen: "signIn",
      userData: { countryCode: "" },
    },
    signUp: {
      currentScreen: "signUp",
      userData: { inputData: "" },
    },
    forgotPassword: {
      currentScreen: "forgotPassword",
      userData: { inputData: "" },
    },
    singleSignOn: {
      currentScreen: "signIn",
      userData: { countryCode: "", countryIso: "" },
    },
  },
  action
) {
  switch (action.type) {
    case UPDATE_SIGN_IN:
      return { ...state, signIn: { ...state.signIn, ...get(action, ["payload"], {}) } };
    case UPDATE_SIGN_UP:
      return { ...state, signUp: { ...get(action, ["payload"], {}) } };
    case UPDATE_FORGOT_PASSWORD:
      return { ...state, forgotPassword: { ...get(action, ["payload"], {}) } };
    case UPDATE_SINGLE_SIGN_ON:
      return { ...state, singleSignOn: { ...get(action, ["payload"], {}) } };
    default:
      return state;
  }
}

function meteringIndicatorReducer(state = true, action) {
  switch (action.type) {
    case "SHOW_METERING_INDICATOR":
      return true;
    case "HIDE_METERING_INDICATOR":
      return false;
    default:
      return state;
  }
}

function memberSubscriptionsReducer(state = [], action) {
  switch (action.type) {
    case "MEMBER_SUBSCRIPTIONS_UPDATED":
      if (action && action.subscriptions) {
        return [...action.subscriptions];
      }
      return [];
    default:
      return state;
  }
}

function memberMetadataReducer(
  state = { showMemberMetadataPopup: true, isMemberMetadataUpdated: false, data: {} },
  action
) {
  switch (action.type) {
    case SHOW_MEMBER_METADATA_POPUP:
      return { ...state, showMemberMetadataPopup: action.showMemberMetadataPopup };
    case MEMBER_METADATA_UPDATED:
      return { ...state, isMemberMetadataUpdated: action.isMemberMetadataUpdated };
    case MEMBER_METADATA:
      return { ...state, data: action.data };
    default:
      return state;
  }
}

function memberReducer(state = null, action) {
  switch (action.type) {
    case "MEMBER_UPDATED":
      return action.member;
    default:
      return state;
  }
}
function memberLoadingReducer(state = false, action) {
  switch (action.type) {
    case "MEMBER_LOADED":
      return action.memberLoaded;
    default:
      return state;
  }
}

function memberEmailReducer(state = null, action) {
  switch (action.type) {
    case "MEMBER_EMAIL_UPDATED":
      return action.userEmail;
    default:
      return state;
  }
}

export const CUSTOM_REDUCERS = {
  header: headerReducer,
  login: loginReducer,
  showMeteringIndicator: meteringIndicatorReducer,
  memberSubscriptions: memberSubscriptionsReducer,
  member: memberReducer,
  memberEmail: memberEmailReducer,
  memberMetadata: memberMetadataReducer,
  memberLoaded: memberLoadingReducer,
};
